import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import {
  PositionContainer,
  FlexContainer,
  Container
} from "components/Atoms/Containers";
import { Caption, Body2, Subtitle2 } from "components/Atoms/Text";
import Button from "components/Blocks/Forms/Button";
import closeIcon from "assets/closeIcon";
import downloadIcon from "assets/downloadIcon";

const ImportModal = ({ setImportToggleState, fieldProps }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [reader] = useState(new FileReader());
  const { setValue } = useFormContext();
  const { append, remove, fields } = fieldProps;

  useEffect(() => {
    if (file && loading) {
      reader.readAsText(file);
    }
  }, [loading, file]);

  // Enable file upload functionality
  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
    setLoading(true);

    // Triggered after successful or unsuccessful file reading
    reader.onloadend = () => {
      setLoading(false);
      handleCloseModal();
    };

    // Triggered after successful file reading
    reader.onload = () => {
      // 1. Split results by line break
      // 2. Remove first element which is a header
      // 3. Add empty value at the end to render new input
      let results = reader.result.split("\n").slice(1);
      results.push("");

      // Iterate through and add values to form after any existing values
      let fieldIndex;
      if (fields.length > 1) {
        fieldIndex = fields.length - 1;
        remove(fieldIndex);
      } else {
        fieldIndex = 0;
      }

      const values = results.map(result => (result = { value: result }));

      results.forEach(result => {
        const key = `recipients[${fieldIndex}].value`;
        setValue(key, result, { shouldDirty: true });
        fieldIndex++;
      });
      append(values);
    };
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDrag: true,
    multiple: false,
    accept: [".csv"]
  });

  // Handle importing csv on click
  const handleImportSpreadsheet = () => {
    getRootProps().ref.current.click();
  };

  // Close modal
  const handleCloseModal = () => {
    setImportToggleState(false);
  };

  // Set table data
  const tableData = [
    { id: 1, email: "Andy@c1.com" },
    { id: 2, email: "Haris@c1.com" },
    { id: 3, email: "Michael@c1.com" }
  ];

  // Render logic for info table
  const renderTable = () => {
    return tableData.map(row => {
      return (
        <TableRow key={row.id}>
          <Body2 color="#444444">{row.email}</Body2>
        </TableRow>
      );
    });
  };

  return (
    <ModalContainer position="absolute" top="100%" left="0">
      <CloseSVGContainer
        onClick={handleCloseModal}
        className="pointer"
        position="absolute"
        top="16px"
        right="16px"
      >
        {closeIcon}
      </CloseSVGContainer>
      <Subtitle2 margin="0 0 28px" textAlign="left">
        Import .CSV
      </Subtitle2>
      <DownloadTemplateContainer
        margin="0 0 16px"
        width="100%"
        justify="space-between"
      >
        <Body2>Example format</Body2>
        <a
          href="https://storage.googleapis.com/c1-assessments-proctoring-binaries/CSV-import-format-example.csv"
          download="CSV_Import_Upload_Example"
        >
          <FlexContainer align="center">
            <Container margin="-2px 0 0">{downloadIcon}</Container>
            <DownloadText>Download Template</DownloadText>
          </FlexContainer>
        </a>
      </DownloadTemplateContainer>
      <Table>
        <TableHeader>
          <Caption color="#919191">Email</Caption>
        </TableHeader>
        {renderTable()}
      </Table>
      <HiddenInput {...getRootProps()}>
        <input {...getInputProps()} />
      </HiddenInput>
      <Button
        disabled={loading}
        loading={loading}
        onClick={handleImportSpreadsheet}
        text="IMPORT .CSV"
        type="text"
      />
    </ModalContainer>
  );
};

export default ImportModal;

const DownloadTemplateContainer = styled(FlexContainer)`
  a {
    color: ${({ theme }) => theme.tenantAccent};
    text-decoration: none;
  }
`;

const CloseSVGContainer = styled(PositionContainer)`
  z-index: 102;
  svg {
    height: 14px;
    width: 14px;
  }
`;

const HiddenInput = styled.div`
  display: none;
`;

const ModalContainer = styled(PositionContainer)`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);
  padding: 16px;
  z-index: 101;
`;

const Table = styled(Container)`
  border-left: 1px solid #e2e3e3;
  border-right: 1px solid #e2e3e3;
  margin-bottom: 42px;
  min-width: 300px;
`;

const TableHeader = styled(FlexContainer)`
  background: #f8f9fa;
  border-bottom: 1px solid #e2e3e3;
  border-top: 1px solid #e2e3e3;
  padding: 4px 8px 2px;
  text-align: left;
  white-space: nowrap;
`;

const TableRow = styled(FlexContainer)`
  border-bottom: 1px solid #e2e3e3;
  padding: 8px 8px 4px;
  text-align: left;
  white-space: nowrap;
`;

const DownloadText = styled(Body2)`
  color: ${({ theme }) => theme.tenantAccent};
  margin-left: 8px;
`;
